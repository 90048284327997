<template>
  <div v-bind:class="moveTableLeft ? 'content-sidebar-left' : ''">
    <div class="w3-container flow-main-content w3-center w3-padding">
      <div class="flow-card blue-top w3-padding w-98pc">
        <table class="w3-table w3-bordered flow-table">
          <thead>
            <tr class="table-header-row">
              <th>{{ $t("name") }}</th>
              <th>{{ $t("ipOrDomain") }}</th>
              <th>{{ $t("port") }}</th>
              <th>{{ $t("printService") }}</th>
            </tr>
          </thead>
          <tbody v-show="printers.length === 0">
            <tr>
              <td class="w3-center flow-main-color" colspan="8">
                <b class="font-size-1pt25em">{{ $t("noPrinters") }}</b>
              </td>
            </tr>
          </tbody>

          <tbody
            v-show="printers.length !== 0"
            v-for="printer in printers"
            :key="printer"
          >
            <tr
              v-bind:id="printer.id"
              :key="printer.id"
              class="no-hover w3-border-0"
            >
              <td class="standard-box-size top">
                <StandardTextBox
                  :text="printer.name"
                  @update-text="
                    u => {
                      printer.name = u;
                    }
                  "
                />
                <div class="error-box">
                  <span class="w3-text-red">{{
                    printerNameError[printer.printerId]
                  }}</span>
                </div>
              </td>
              <td class="standard-box-size top">
                <StandardTextBox
                  :text="printer.ipDomain"
                  @update-text="
                    u => {
                      printer.ipDomain = u;
                    }
                  "
                />
                <div class="error-box">
                  <span class="w3-text-red">{{
                    ipOrDomainError[printer.printerId]
                  }}</span>
                </div>
              </td>
              <td class="port-size top">
                <StandardTextBox
                  type="number"
                  :text="printer.port.toString()"
                  @update-text="
                    u => {
                      printer.port = u;
                    }
                  "
                  :is-number="true"
                />
                <div class="error-box">
                  <span class="w3-text-red">{{
                    portError[printer.printerId]
                  }}</span>
                </div>
              </td>
              <td class="port-size top">
                <StandardSelect
                  class="w3-border w3-round-medium dropdown-height"
                  type="text"
                  :items="printerServices"
                  :pre-selected-item="
                    preSelectedPrinterService(printer.printService)
                  "
                  :valueKey="'value'"
                  :valueKeyIndex="'key'"
                  :callback="
                    u => {
                      printer.printService = u.value;
                    }
                  "
                />
              </td>
              <td class="td-buttons">
                <button
                  class="flow-btn w3-padding-small w3-round-medium margin-buttons"
                  v-bind:class="
                    showSaveButton(printer) ? 'cursor-pointer' : 'w3-disabled'
                  "
                  @click.prevent="updatePrinter(printer)"
                >
                  <i
                    class="fa fa-check-circle w3-large flow-green-active-color"
                  ></i>
                </button>

                <button
                  class="margin-buttons cursor-pointer flow-btn w3-padding-small w3-round-medium"
                  @click="deletePrinter(printer)"
                >
                  <i class="fa fa-trash w3-text-deep-orange"></i>
                </button>
              </td>
            </tr>
            <tr class="no-hover w3-border-0">
              <td colspan="5">
                <div v-if="printer.clickedDelete">
                  <span class="w3-center w3-col w-100pc w3-margin w3-padding">
                    <span
                      class="w3-large font-weight-bold flow-main-color flow-margin-right"
                      >{{ $t("delete") }}
                      <span
                        class="w3-large font-weight-bold w3-text-deep-orange"
                        >{{ printer.name }}</span
                      >
                      ?
                    </span>
                    <span
                      class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                      @click.prevent="confirmDeletion(printer)"
                    >
                      <i class="fa fa-trash w3-text-deep-orange"></i>
                      <span
                        class="flow-main-color flow-margin-left font-weight-bold w3-large"
                        >{{ $t("yes") }}</span
                      >
                    </span>

                    <span
                      class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                      @click.prevent="cancelDeletion(printer)"
                    >
                      <i class="fa fa-times flow-main-color"></i>
                      <span
                        class="flow-main-color flow-margin-left font-weight-bold w3-large"
                        >{{ $t("no") }}</span
                      >
                    </span>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import StandardSelect from "@/components/views/opening-hours/StandardSelect.vue";
import StandardTextBox from "@/components/common/StandardTextBox.vue";

export default {
  name: "PrinterTable",
  components: { StandardTextBox, StandardSelect },
  emits: ["success", "failure"],
  props: {
    /**
     * Moves the table left when the sidebar on the right opens
     */
    moveTableLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    newPrinterAdded: {
      required: true,
      default: false
    }
  },
  watch: {
    newPrinterAdded() {
      this.getAllPrinters();
    }
  },
  data() {
    return {
      printers: [],
      printerServices: [
        {
          key: "QKEP",
          value: "Custom"
        },
        {
          key: "ZEP",
          value: "Zebra"
        }
      ],
      selectedPrinterService: {},
      tableDataInSyncWithDb: {},
      contentLoaded: false,
      updateExecuted: false,
      deleteExecuted: false,
      printerNameError: {},
      ipOrDomainError: {},
      portError: {}
    };
  },
  mounted() {
    this.getAllPrinters();
  },
  computed: {
    getPrintersSortedByName() {
      let printers = JSON.parse(JSON.stringify(this.printers));
      this.sortPrintersByName(printers);
      return printers;
    }
  },
  methods: {
    preSelectedPrinterService(printService) {
      return {
        key: printService,
        value: printService
      };
    },
    showSaveButton(printer) {
      let printerSyncedWithDb = this.tableDataInSyncWithDb[printer.printerId];
      if (printerSyncedWithDb === undefined) return false;
      else {
        return (
          printerSyncedWithDb.name !== printer.name ||
          printerSyncedWithDb.ipDomain !== printer.ipDomain ||
          printerSyncedWithDb.port.toString() !== printer.port.toString() ||
          printerSyncedWithDb.printService !== printer.printService
        );
      }
    },
    updatePrinter(printer) {
      if (
        this.updateExecuted ||
        !this.showSaveButton(printer) ||
        !this.isInputValid(printer)
      )
        return;
      this.updateExecuted = true;

      const createPrinterRequest = {
        printerId: printer.printerId,
        name: printer.name,
        ipDomain: printer.ipDomain,
        port: printer.port,
        printService: this.mapPrintServiceNameToBackendName(printer.printService)
      };

      this.$restClient.printers
        .update(createPrinterRequest)
        .then(response => {
          console.log(response);
          this.$emit("success", response);
        })
        .catch(err => {
          this.updateExecuted = false;
          console.log(err);
          this.$emit("failure", err);
        });
    },
    deletePrinter(printer) {
      printer.clickedDelete = true;
    },
    confirmDeletion(printer) {
      if (this.deleteExecuted) return;
      this.deleteExecuted = true;
      this.$restClient.printers
        .delete(printer.printerId)
        .then(response => {
          console.log(response);
          console.log(this.printers);
          this.$emit("success", printer.printerId);
        })
        .catch(err => {
          this.deleteExecuted = false;
          console.log(err);
          this.$emit("failure", err);
        });
    },
    cancelDeletion(printer) {
      printer.clickedDelete = false;
    },
    getAllPrinters() {
      this.$restClient.printers
        .all()
        .then(response => {
          this.contentLoaded = true;
          let data = response.data;
          this.sortPrintersByName(data);
          // this.printers = data;

          this.printers = data.map(d => ({
            printerId: d.printerId,
            name: d.name,
            ipDomain: d.ipDomain,
            port: d.port,
            printService: this.mapPrintServiceName(d.printService)
          }))

          let tableDataInSyncWithDb = {};
          this.printers.forEach(function(d) {
            tableDataInSyncWithDb[d.printerId] = {
              name: d.name,
              ipDomain: d.ipDomain,
              port: d.port,
              printService: d.printService
            };
          });
          this.tableDataInSyncWithDb = tableDataInSyncWithDb;
          this.updateExecuted = false;
          this.deleteExecuted = false;
        })
        .catch(err => {
          console.log(err);
          this.$notify.notifyError({ title: this.$t("failed") });
        });
    },
    isNameValid(printer) {
      let printerNameError = "";

      if (printer.name === "") {
        printerNameError = this.$t("INVALID_TEXTFIELD");
        this.printerNameError[printer.printerId] = printerNameError;
        return false;
      }

      if (printer.name.length > 30) {
        printerNameError = this.$t("INVALID_PRINTER_NAME");
        this.printerNameError[printer.printerId] = printerNameError;
        return false;
      }
      return true;
    },
    isIpOrDomainValid(printer) {
      let ipOrDomainError;

      if (printer.ipDomain === "") {
        ipOrDomainError = this.$t("INVALID_TEXTFIELD");
        this.ipOrDomainError[printer.printerId] = ipOrDomainError;
        return false;
      }
      return true;
    },
    isPortValid(printer) {
      let portError = "";

      if (printer.port === "") {
        portError = this.$t("INVALID_TEXTFIELD");
        this.portError[printer.printerId] = portError;
        return false;
      }

      if (printer.port < 0 || printer.port > 65535) {
        portError = this.$t("INVALID_PORT");
        this.portError[printer.printerId] = portError;
        return false;
      }
      return true;
    },
    isInputValid(printer) {
      this.printerNameError = {};
      this.ipOrDomainError = {};
      this.portError = {};
      return (
        this.isNameValid(printer) &&
        this.isIpOrDomainValid(printer) &&
        this.isPortValid(printer)
      );
    },
    sortPrintersByName(printers) {
      printers.sort((printer1, printer2) =>
        printer1.name.localeCompare(printer2.name)
      );
    },
    mapPrintServiceName(printService) {
      if (printService === "QKEP") return "Custom";
      if (printService === "ZEP") return "Zebra";
      return null;
    },
    mapPrintServiceNameToBackendName(printService) {
      if (printService === "Custom") return "QKEP";
      if (printService === "Zebra") return "ZEP";
      return null;
    }
  }
};
</script>

<style scoped>
.dropdown-height {
  max-height: 41px;
  max-width: 120px;
}

.standard-box-size {
  width: 20%;
  max-height: 41px;
}

.error-box {
  min-height: 60px;
}

.port-size {
  width: 10%;
  max-height: 41px;
}

th {
  text-align: left;
}

.table-header-row:hover {
  background-color: white;
}

.top {
  vertical-align: top;
}

.td-buttons {
  vertical-align: top;
  width: 15%;
  max-width: 20%;
}

.margin-buttons {
  margin: 4px 8px;
  width: 35px;
  height: 35px;
}
</style>
