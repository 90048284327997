<template>
  <div class="w3-left-align">
    <i class="fa fa-door-open flow-main-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('addNewRoom')"></content-header>
    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">
        <p class="flow-padding-bottom zero-top w-100pc">
          <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("name") }}</label>
          <input v-model="newRoom.name" class="w3-input w3-border w3-round-medium"
                 type="text"
                 v-bind:placeholder="$t('name')"/>
          <span class="w3-text-red">{{ npNameError }}</span>
        </p>
      </div>

      <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input qpp-room-route">
        <p class="flow-padding-bottom zero-top w-100pc">
          <label class="w3-left flow-main-color flow-padding-bottom w-100pc">{{ $t("route") }}</label>
          <input v-model="newRoom.route"
                 class="w3-input w3-border w3-round-medium w-60pc display-inline flow-margin-right-20-px"
                 maxlength="1" type="text" v-bind:placeholder="$t('route')"/>
          <span class="w3-input w3-round-medium w-25pc qpp-room-route-field w3-show-inline-block ">
            <flow-select
                v-model="newRoom.color"
                :callback="setSelectedColor"
                :hasBackgroundColor=true
                :items="colors"
                :mainItem="newRoom"
                :valueKey="''"
                :valueKeyIndex="'colorCode'"
                type="text"></flow-select></span>

        </p>
      </div>

      <div class="zero-bottom zero-top">
        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                @click.prevent="addNewRoom">
          <i class="fa fa-save flow-green-active-color">
            <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
          </i>
        </button>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top"
                @click.prevent="onClose">
          <i class="fa fa-arrow-left w3-text-red">
            <span><b class="flow-margin-left">{{ $t('back') }}</b></span>
          </i>
        </button>
      </div>

    </div>
  </div>
</template>
<script>
import ContentHeader from "../../common/ContentHeader";
import FlowSelect from "@/components/common/FlowSelect";
import colors from '../../../ColorOptions.json'

export default {
  name: 'NewWaitingRoom',
  components: {ContentHeader, FlowSelect},
  emits: ['addedNewRoom'],
  props: ['onClose'],
  data() {
    return {
      newRoom: {},
      npNameError: undefined,
      colors: colors
    }
  },
  methods: {
    addNewRoom() {
      this.npNameError = undefined;

      if (!this.newRoom.name || !this.newRoom.name.trim()) {
        this.npNameError = this.$t('INVALID_NAME');
      }

      if (this.npNameError) return;
      const app = this;
      app.$restClient.waitingRooms.add(this.newRoom).then(response => {
        // eslint-disable-next-line no-unused-vars
        let data = response.data;
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t('added-success')});
        this.$emit("addedNewRoom", data);
        app.onClose();
      }).catch(err => {
        if (err && err.response.status === 400)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('waiting-room-name-unique')});
        else
          app.$notify.notifyError({title: this.$t("failed")});
      });

    },

    setSelectedColor(color, _room) {
      _room.color = color.colorCode;
    },

  }
}
</script>