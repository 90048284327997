<template>
  <div class="w3-left-align">
    <i class="fa fa-inbox flow-main-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('editMessageBoard')"></content-header>

    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">

        <div class="w3-container zero-bottom w3-block flow-form-inline-input">
          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("publication-date") }}</label>
            <input type="date" v-model="message.publicationDate" max="9999-12-31" pattern="\d{4}-\d{2}-\d{2}"
                         class="w3-input w3-border w3-round-medium"/>
            <span class="w3-text-red">{{ npPubDateError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("message-importance") }}</label>
            <flow-select :callback="setSelectedImportance" :items="messageImportanceOptions"
                         :preSelectedItem="setPreSelectedItem(message.messageImportance)"
                         :valueKey="'name'"
                         :valueKeyIndex="'id'"
                         class="w3-border w3-round-medium"
                         type="text"></flow-select>
          </p>

          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("title") }}</label>
            <input type="text" v-model="message.title" maxlength="80"
                   class="w3-input w3-border w3-round-medium"/>
            <span class="w3-text-red" v-if="message.title === undefined">{{ npTextError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("expiry-date") }}</label>
            <input type="date" v-model="message.expiryDate"  max="9999-12-31"
                   class="w3-input w3-border w3-round-medium"/>
            <span class="w3-text-red">{{ npExpDateError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-90pc flow-flex-col w3-left">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("description") }}</label>
            <textarea v-model="message.description" class="w3-input w3-border w3-round-medium"
                        rows="4" type="text" />
            <span class="w3-text-red" v-if="message.description === undefined">{{ npTextError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-90pc flow-flex-col w3-left">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("comment") }}</label>
            <textarea v-model="message.comment" class="w3-input w3-border w3-round-medium"
                      rows="4" type="text" />
            <span class="w3-text-red" v-if="message.comment === undefined">{{ npTextError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-90pc flow-flex-col w3-left">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("updated-at") }}</label>
            <input v-model="message.updatedAt" class="w3-input w3-border w3-round-medium"
                      type="text" disabled/>
          </p>

        </div>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                @click.prevent="updateMessage">
          <i class="fa fa-save flow-green-active-color">
            <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
          </i>
        </button>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top"
                @click.prevent="onClose">
          <i class="fa fa-arrow-left w3-text-red">
            <span><b class="flow-margin-left">{{ $t('back') }}</b></span>
          </i>
        </button>
      </div>

    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import FlowSelect from "@/components/common/FlowSelect";

export default {
  name: "EditMessage",
  components: {ContentHeader, FlowSelect},
  emits: ['updatedMessage'],
  props: ['onClose', 'messages', 'currentMessage',"messageImportanceOptions"],
  data() {
    return {
      message: {},
      npPubDateError: undefined,
      npExpDateError: undefined,
      npTextError: undefined
    }
  },
  watch: {
    currentMessage() {
      if (this.currentMessage != null && this.currentMessage.id != null){
        this.getMessageDetails();
      }
    }
  },
  mounted() {
    if (this.currentMessage != null && this.currentMessage.id != null)
      this.getMessageDetails();
  },

  methods: {
    getMessageDetails() {
      const app = this;
      app.$restClient.messages.get(this.currentMessage.id).then(response => {
        this.message = response.data;
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title:this.$t("failed")});
      });
    },

    setPreSelectedItem() {
      for(let i=0; i<=this.messageImportanceOptions.length; i++) {
        if(this.currentMessage.messageImportance === this.messageImportanceOptions[i].id) {
          return this.messageImportanceOptions[i];
        }
      }
    },

    setSelectedImportance(option) {
        this.message.messageImportance = option.id;
    },

    updateMessage() {
      this.npPubDateError = undefined;
      this.npExpDateError = undefined;
      this.npTextError = undefined;

      if(this.message.title === undefined || this.message.title === "" ||
          this.message.description === undefined || this.message.description === "" ||
          this.message.comment === undefined || this.message.comment === "")
        this.npTextError = this.$t('INVALID_TEXTFIELD');

      if(this.message.publicationDate.split('-')[0].length !== 4)
        this.npPubDateError = this.$t('INVALID_DATE');
      if(this.message.expiryDate.split('-')[0].length !== 4)
        this.npExpDateError = this.$t('INVALID_DATE');

      if (this.npPubDateError || this.npExpDateError || this.npTextError) return;

      let today = new Date().setHours(0,0,0,0);
      let pubDate = new Date(this.message.publicationDate).setHours(0,0,0,0);
      if(pubDate < today) this.npPubDateError = this.$t('INVALID_PUBDATE');

      let expDate = new Date(this.message.expiryDate).setHours(0,0,0,0);
      if(expDate < today || expDate < pubDate) this.npExpDateError = this.$t('INVALID_EXPDATE');

      if (this.npPubDateError || this.npExpDateError) return;

      const app = this;
      app.$restClient.messages.update(this.message).then(response => {
        let data = response.data;
        this.$emit("updatedMessage", data);
        app.onClose;
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")})
      });
    }

  }
}
</script>