<template>
  <div class="w3-left-align">
    <i class="fa fa-inbox flow-main-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('addNewMessage')"></content-header>

    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">

        <div class="w3-container zero-bottom w3-block flow-form-inline-input">
          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("publication-date") }}</label>
            <input type="date" v-model="newMessage.publicationDate" max="9999-12-31"  pattern="\d{4}-\d{2}-\d{2}"
                   class="w3-input w3-border w3-round-medium"/>
            <span class="w3-text-red">{{ npPubDateError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("message-importance") }}</label>
            <flow-select :callback="setSelectedImportance" :items="messageImportanceOptions"
                         :valueKey="'name'"
                         :valueKeyIndex="'id'"
                         class="w3-border w3-round-medium"
                         type="text"></flow-select>
          </p>

          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("title") }}</label>
            <input type="text" v-model="newMessage.title" maxlength="80"
                   class="w3-input w3-border w3-round-medium"/>
            <span class="w3-text-red" v-if="newMessage.title === undefined">{{ npTextError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("expiry-date") }}</label>
            <input type="date" v-model="newMessage.expiryDate"  max="9999-12-31"
                   class="w3-input w3-border w3-round-medium"/>
            <span class="w3-text-red">{{ npExpDateError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-90pc flow-flex-col w3-left">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("description") }}</label>
            <textarea v-model="newMessage.description" class="w3-input w3-border w3-round-medium"
                      rows="4" type="text" maxlength="1000" />
            <span class="w3-text-red" v-if="newMessage.description === undefined">{{ npTextError }}</span>
          </p>

          <p class="flow-padding-bottom zero-top w-90pc flow-flex-col w3-left">
            <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("comment") }}</label>
            <textarea v-model="newMessage.comment" class="w3-input w3-border w3-round-medium"
                      rows="4" type="text" maxlength="1000" />
            <span class="w3-text-red" v-if="newMessage.comment === undefined">{{ npTextError }}</span>
          </p>
        </div>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                @click.prevent="addNewMessage">
          <i class="fa fa-save flow-green-active-color">
            <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
          </i>
        </button>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top"
                @click.prevent="onClose">
          <i class="fa fa-arrow-left w3-text-red">
            <span><b class="flow-margin-left">{{ $t('back') }}</b></span>
          </i>
        </button>
      </div>

    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import FlowSelect from "@/components/common/FlowSelect";

export default {
  name: "NewMessage",
  components: {ContentHeader, FlowSelect},
  emits: ['newMessageAdded'],
  props: ['onClose','messageImportanceOptions'],

  data() {
    return {
      newMessage: { },
      npPubDateError: undefined,
      npExpDateError: undefined,
      npTextError: undefined
    }
  },

  mounted() {
    const today = new Date();
    this.newMessage.publicationDate = this.$helper.getDateAsYYYYMMDD(today);
    var newDate = new Date(today.setMonth(today.getMonth()+6));
    this.newMessage.expiryDate = this.$helper.getDateAsYYYYMMDD(newDate);
  },

  methods: {

    addNewMessage() {
      this.npPubDateError = undefined;
      this.npExpDateError = undefined;
      this.npTextError = undefined;

      if(this.newMessage.title === undefined || this.newMessage.title === "" ||
          this.newMessage.description === undefined || this.newMessage.description === "" ||
          this.newMessage.comment === undefined || this.newMessage.comment === "")
        this.npTextError = this.$t('INVALID_TEXTFIELD');
      if(this.newMessage.publicationDate.split('-')[0].length !== 4)
        this.npPubDateError = this.$t('INVALID_DATE');
      if(this.newMessage.expiryDate.split('-')[0].length !== 4)
        this.npExpDateError = this.$t('INVALID_DATE');

      if (this.npPubDateError || this.npExpDateError || this.npTextError) return;

      let today = new Date().setHours(0,0,0,0);
      let pubDate = new Date(this.newMessage.publicationDate).setHours(0,0,0,0);
      if(pubDate < today) this.npPubDateError = this.$t('INVALID_PUBDATE');

      let expDate = new Date(this.newMessage.expiryDate).setHours(0,0,0,0);
      if(expDate < today || expDate < pubDate) this.npExpDateError = this.$t('INVALID_EXPDATE');

      if (this.npPubDateError || this.npExpDateError) return;

      const app = this;
      app.$restClient.messages.add(this.newMessage).then(response => {
        let data = response.data;
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t('added-success')});
        this.$emit("newMessageAdded", data);
        app.onClose;
      }).catch(err => {
        console.log(err);
        // if(err && err.response.status === 400)
        //   app.$notify.notifyError({title: this.$t("failed"), message: this.$t('duplicate-st-text')});
        // else
        app.$notify.notifyError({title: this.$t("failed")});
      });
    },

    setSelectedImportance(option) {
      this.newMessage.messageImportance = option.id;
    }

  }
}
</script>