<template>
  <div class="w3-left-align">
    <i class="fa fa-clock flow-main-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('modifyOpeningHour')"></content-header>

    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <Column>
        <Row>
          <div class="grid-component">
            <StandardTextBox
              :np-name-error="npNameError"
              :text="onSelectedOpeningHour.day.value"
              :label-name="$t('day')"
              is-disabled="is-disabled"
              @update-text="args => (day.value = args)"
            />
          </div>
          <div class="grid-component">
            <StandardTextBox
              is-disabled="is-disabled"
              :np-name-error="npNameError"
              :text="onSelectedOpeningHour.regimeName"
              :label-name="$t('regime')"
              @update-text="text => (selectedOpeningHour.regimeName = text)"
            />
          </div>
        </Row>

        <Row>
          <div class="grid-component">
            <StandardTextBox
              v-model:text="onSelectedOpeningHour.startTime"
              :np-name-error="startTimeError"
              :text="onSelectedOpeningHour.startTime"
              :label-name="$t('start-time')"
              :placeholder="'08:00'"
              @update-text="text => (newStartTime = text)"
            />
          </div>
          <div class="grid-component">
            <StandardTextBox
              v-model:text="onSelectedOpeningHour.endTime"
              :np-name-error="endTimeError"
              :text="onSelectedOpeningHour.endTime"
              :label-name="$t('end-time')"
              :placeholder="'20:00'"
              @update-text="text => (newEndTime = text)"
            />
          </div>
        </Row>
      </Column>
      <Column>
        <Row>
          <div class="form-button">
            <SaveButton
              :function-executed="updateExecuted"
              :on-add="updateOpeningHour"
            />
          </div>

          <div class="form-button">
            <DeleteButton :on-delete="deleteOpeningHour" />
          </div>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StandardTextBox from "@/components/common/StandardTextBox.vue";
import Column from "@/components/common/grid/Column.vue";
import Row from "@/components/common/grid/Row.vue";
import ContentHeader from "@/components/common/ContentHeader.vue";
import SaveButton from "@/components/common/buttons/SaveButton.vue";
import DeleteButton from "@/components/common/buttons/DeleteButton.vue";

export default {
  name: "UpdateOpeningHourSideBar",
  components: {
    DeleteButton,
    SaveButton,
    ContentHeader,
    Row,
    Column,
    StandardTextBox
  },
  emits: ["success", "failure"],
  props: [],
  data() {
    return {
      updateExecuted: false,
      npNameError: "",
      startTimeError: "",
      endTimeError: "",
      /**
       * Need to keep track of the original startTime, because this needs to be passed as a query in the api to determine
       * what domainTimings need to get updated or deleted
       */
      oldStartTime: "",
      /**
       * Need to keep track of the original startTime, because this needs to be passed as a query in the api to determine
       * what domainTimings need to get updated or deleted
       */
      oldEndTime: "",
      newStartTime: "",
      newEndTime: "",
      selectedOpeningHour: {},
      /**
       * @example {key: "MONDAY", value: "Maandag"}
       */
      day: {}
    };
  },
  computed: {
    ...mapGetters({
      onSelectedOpeningHour: "eventHandlers/onSelectedOpeningHour"
    })
  },
  created() {
    this.selectedOpeningHour = this.onSelectedOpeningHour;
    this.oldStartTime = this.onSelectedOpeningHour.startTime;
    this.newStartTime = this.onSelectedOpeningHour.startTime;
    this.oldEndTime = this.onSelectedOpeningHour.endTime;
    this.newEndTime = this.onSelectedOpeningHour.endTime;
    this.day = this.onSelectedOpeningHour.day;
  },
  methods: {
    updateOpeningHour() {
      if (!this.isInputValid()) return;
      this.updateExecuted = true;

      const createOpeningHourRequest = {
        id: null,
        day: this.selectedOpeningHour.day.key,
        startTime: this.newStartTime,
        endTime: this.newEndTime,
        regimeId: this.selectedOpeningHour.regimeId
      };

      this.$restClient.domainTimings
        .updateDomainTimingsByRegime(
          createOpeningHourRequest,
          this.selectedOpeningHour.regimeId,
          this.createQueryStringForUpdate()
        )
        .then(response => {
          console.log(response);
          this.$emit("success");
        })
        .catch(err => {
          this.updateExecuted = false;
          console.log(err);
          this.$emit("failure", err);
        });
    },
    /**
     * @return Boolean
     */
    isInputValid() {
      let startTimeError = "";
      let endTimeError = "";

      if (this.newStartTime === "")
        startTimeError = this.$t("INVALID_TEXTFIELD");

      if (this.newEndTime === "") endTimeError = this.$t("INVALID_TEXTFIELD");

      if (!this.$helper.isTimeValid(this.newStartTime))
        startTimeError = this.$t("INVALID TIME");
      if (!this.$helper.isTimeValid(this.newEndTime))
        endTimeError = this.$t("INVALID TIME");

      if (this.newStartTime >= this.newEndTime) {
        startTimeError = this.$t("INVALID TIME PERIOD");
        endTimeError = this.$t("INVALID TIME PERIOD");
      }

      this.startTimeError = startTimeError;
      this.endTimeError = endTimeError;

      return this.startTimeError === "" && this.endTimeError === "";
    },
    deleteOpeningHour() {
      this.$restClient.domainTimings
        .deleteDomainTimingsByRegime(
          this.selectedOpeningHour.regimeId,
          this.createQueryStringForDelete()
        )
        .then(response => {
          console.log(response);
          this.$emit("success");
        })
        .catch(err => {
          console.log(err);
          this.$emit("failure", err);
        });
    },
    createQueryStringForDelete() {
      const query =
        "day=" +
        this.selectedOpeningHour.day.key +
        "&startTime=" +
        this.oldStartTime +
        "&endTime=" +
        this.oldEndTime;
      return query;
    },
    createQueryStringForUpdate() {
      return "oldSt=" + this.oldStartTime + "&oldEt=" + this.oldEndTime;
    }
  },
  watch: {
    onSelectedOpeningHour() {
      this.selectedOpeningHour = this.onSelectedOpeningHour;
      this.oldStartTime = this.onSelectedOpeningHour.startTime;
      this.newStartTime = this.onSelectedOpeningHour.startTime;
      this.oldEndTime = this.onSelectedOpeningHour.endTime;
      this.newEndTime = this.onSelectedOpeningHour.endTime;
      this.day = this.onSelectedOpeningHour.day;
    }
  }
};
</script>
