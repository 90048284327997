<template>
  <div>
    <div>
      <div class="w3-container flow-main-content w3-center w3-padding w3-ripple">
      </div>
      <div class="w3-container flow-card blue-top w3-animate-opacity flow-desks-content w-60pc">
        <loading v-bind:dspin="!contentLoaded"></loading>
        <table v-show="contentLoaded" class="w3-table w3-bordered flow-table">
          <thead class="flow-main-color">
          <th>{{ $t("name") }}</th>
          <th></th>
          </thead>
          <tbody>
          <tr v-show="languages.length === 0">
            <td class="w3-center flow-main-color" colspan="8">
              <b class="font-size-1pt25em">{{ $t("noLanguages") }}</b>
            </td>
          </tr>
          </tbody>

          <tbody class="">
          <div v-for="(language, index) in languages" :key="language.id">
            <tr :key="language.id" class="no-hover w3-border-0">
              <td>
                <input v-model="language.name" class="w3-input w3-border w3-round-medium left" type="text"
                       disabled="disabled"
                       v-bind:placeholder="$t('name')"/>
                <span class="w3-text-red">{{ language.nameError }}</span>
              </td>

              <td class="qpp-inline-buttons">
                <span class="btn flow-btn w3-padding-small w3-round-medium languages-btn"
                      v-bind:class="index !== 0? 'cursor-pointer' : 'w3-disabled'"
                      @click.prevent="move(index, index-1)">
                  <i class="fa fa-arrow-up w3-large flow-green-active-color"></i>
                </span>

                <span class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                    v-bind:class="index !== languages.length-1? 'cursor-pointer' : 'w3-disabled'"
                    @click.prevent="move(index, index+1)">
                      <i class="fa fa-arrow-down w3-large flow-green-active-color"></i>
                  </span>
              </td>
            </tr>
          </div>
          <td class="languages-save-col" v-if="languages.length > 0" >
          <span class="btn flow-btn w3-padding-small w3-round-medium"
                @click.prevent="saveSortOrder()">
<!--                v-bind:class=" !buttonTo() ? 'cursor-pointer' : 'w3-disabled'">-->

                  <i class="fa fa-check-circle w3-large flow-green-active-color"></i>
                  <span
                      class="flow-green-active-color flow-margin-left font-weight-bold w3-large">{{
                      $t('confirm')
                    }}</span>
                </span>
            <span
                class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                @click="resetSortOrder()">
                      <i class="fa fa-undo w3-text-deep-orange"></i>
                      <span
                          class="w3-text-deep-orange flow-margin-left font-weight-bold w3-large">{{
                          $t('reset')
                        }}</span>
                  </span>

          </td>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>
<script>

import Loading from "../../common/Loading";

export default {
  name: "LanguagesList",
  components: {Loading},
  data() {
    return {
      contentLoaded: false,
      languages: [],
      masterCopy: []
    }
  },
  mounted() {
    this.getAllActiveLanguages();
  },
  methods: {

    getAllActiveLanguages() {
      const app = this;
      app.languages = [];
      app.$restClient.languages.all().then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.languages = data;
          app.createMasterCopy();        }
      }).catch(err => {
          console.log(err);
          app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    move(from, to) {
      this.languages[from].isReordered = true;
      this.languages[to].isReordered = true;
      this.languages[from].sortOrder = from;
      this.languages[to].sortOrder = to;
      this.languages.splice(to, 0, this.languages.splice(from, 1)[0]);
    },

    saveSortOrder() {
      let _list = this.languages.filter((value, index) => {
        value.sortOrder = index + 1;
        return value.isReordered === true;
      });

      const app = this;
      app.$restClient.languages.reorder(_list).then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.languages = data;
          app.createMasterCopy();
          app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("language-order-saved")});
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    resetSortOrder() {
      this.languages = [];
      this.languages = JSON.parse(JSON.stringify(this.masterCopy));
    },

    createMasterCopy() {
      // in this way, a copy is created instead of reference
      this.masterCopy = JSON.parse(JSON.stringify(this.languages));
    }

  }
}

</script>