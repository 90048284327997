module.exports = {
    "local": {
        "localhost:8081": {
            host: "http://localhost:8000",
            redirectUrl: "http://localhost:8081",
            colorScheme: "#006400", // dark green
            synapseApplicationId: "dev.api.qarts.eu"
        }
    },
    "production": {
        // It has a "." because JS is minifying the keys without the quotes
        "view.partheas.uat.qarts.eu": {
            host: "https://api.partheas.uat.qarts.eu",
            redirectUrl: "https://view.partheas.uat.qarts.eu",
            colorScheme: "#232171",
            synapseApplicationId: "api.partheas.uat.qarts.eu"
        },
        "PRODUCTION.TENANT_B": {
            host: "PRODUCTION.TENANT_B_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_B_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_B_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_B_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_C": {
            host: "PRODUCTION.TENANT_C_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_C_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_C_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_C_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_D": {
            host: "PRODUCTION.TENANT_D_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_D_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_D_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_D_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_E": {
            host: "PRODUCTION.TENANT_E_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_E_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_E_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_E_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_F": {
            host: "PRODUCTION.TENANT_F_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_F_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_F_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_F_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_G": {
            host: "PRODUCTION.TENANT_G_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_G_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_G_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_G_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_H": {
            host: "PRODUCTION.TENANT_H_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_H_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_H_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_H_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_I": {
            host: "PRODUCTION.TENANT_I_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_I_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_I_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_I_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_J": {
            host: "PRODUCTION.TENANT_J_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_J_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_J_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_J_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_K": {
            host: "PRODUCTION.TENANT_K_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_K_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_K_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_K_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_L": {
            host: "PRODUCTION.TENANT_L_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_L_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_L_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_L_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_M": {
            host: "PRODUCTION.TENANT_M_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_M_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_M_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_M_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_N": {
            host: "PRODUCTION.TENANT_N_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_N_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_N_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_N_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_O": {
            host: "PRODUCTION.TENANT_O_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_O_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_O_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_O_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_P": {
            host: "PRODUCTION.TENANT_P_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_P_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_P_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_P_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_Q": {
            host: "PRODUCTION.TENANT_Q_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_Q_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_Q_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_Q_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_R": {
            host: "PRODUCTION.TENANT_R_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_R_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_R_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_R_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_S": {
            host: "PRODUCTION.TENANT_S_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_S_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_S_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_S_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_T": {
            host: "PRODUCTION.TENANT_T_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_T_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_T_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_T_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_U": {
            host: "PRODUCTION.TENANT_U_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_U_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_U_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_U_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_V": {
            host: "PRODUCTION.TENANT_V_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_V_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_V_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_V_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_W": {
            host: "PRODUCTION.TENANT_W_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_W_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_W_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_W_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_X": {
            host: "PRODUCTION.TENANT_X_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_X_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_X_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_X_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_Y": {
            host: "PRODUCTION.TENANT_Y_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_Y_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_Y_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_Y_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_Z": {
            host: "PRODUCTION.TENANT_Z_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_Z_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_Z_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_Z_SYNAPSE_APP_ID"
        },
    }
}